import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { getDomainDetailsIN, getDomainChngdDataIN } from "../../utils/api";
import { Spinner, Button, Tabs, Tab, Modal, Alert } from "react-bootstrap";

// Define the custom styles
const CustomTabs = styled(Tabs)`
  .nav-link.active {
    background-color: grey !important;
    color: white !important;
  }

  .nav-link {
    color: black;
  }
`;

const SubscriptionDetailsPaymentSourceIN = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [initialUserList, setInitialUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("others");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalError, setModalError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getDomainDetailsIN();
      console.log(data);
      if (Array.isArray(data) && data.every((item) => Array.isArray(item))) {
        setSubscriptions(data);
        setInitialUserList(data);
      } else {
        console.error("Data format is incorrect");
      }
    } catch (error) {
      console.error(error.message);
    }
    setLoading(false);
  };

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
    const filteredList = initialUserList.filter((subscription) => {
      if (subscription && subscription[1]) {
        return subscription[1].toLowerCase().includes(value.toLowerCase());
      } else {
        return false;
      }
    });
    setSubscriptions(filteredList);
  };

  const handleReset = () => {
    setSearchInput("");
    setSubscriptions(initialUserList);
  };

  const handleRowClick = async (domainName) => {
    setModalLoading(true);
    setShowModal(true);
    setModalError(null);
    try {
      const data = await getDomainChngdDataIN(domainName);
      console.log(data); // Check the structure of the data here
      setModalData(data);
    } catch (error) {
      setModalError("Failed to fetch data. Please try again.");
    }
    setModalLoading(false);
  };

  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };

  const renderTable = (data, showStripeCustomerId) => (
    <div
      className="table-responsive"
      style={{ maxHeight: "500px", overflowY: "auto" }}
    >
      <table className="table table-hover sticky-header">
        <thead>
          <tr>
            <th style={thStyles}>Customer ID</th>
            <th style={thStyles}>Domain Name</th>
            {showStripeCustomerId && (
              <th style={thStyles}>Stripe Customer ID</th>
            )}
            <th style={thStyles}>Payment Source</th>
          </tr>
        </thead>
        <tbody>
          {data.map((subscription, index) => (
            <tr key={index} onClick={() => handleRowClick(subscription[1])}>
              <td>{subscription[0]}</td>
              <td>{subscription[1]}</td>
              {showStripeCustomerId && <td>{subscription[2]}</td>}
              <td>{subscription[3]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const filteredOthers = subscriptions.filter(
    (subscription) => subscription[3].toLowerCase() !== "stripe"
  );
  const filteredStripe = subscriptions.filter(
    (subscription) => subscription[3].toLowerCase() === "stripe"
  );

  return (
    <Fragment>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">INDIA Domain Payment Source Lists</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search by domain name..."
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <Button
                variant="primary"
                className="ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </Button>
              <Button
                variant="secondary"
                className="ml-2"
                onClick={fetchData}
                style={{ float: "left" }}
              >
                Sync
              </Button>
            </div>
            <br />
            <br />
            {loading ? (
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <CustomTabs activeKey={key} onSelect={(k) => setKey(k)}>
                <Tab
                  eventKey="others"
                  title={`Others (${filteredOthers.length})`}
                >
                  {renderTable(filteredOthers, false)}
                </Tab>
                <Tab
                  eventKey="stripe"
                  title={`Stripe (${filteredStripe.length})`}
                >
                  {renderTable(filteredStripe, true)}
                </Tab>
              </CustomTabs>
            )}
          </div>
        </div>
      </div>

      {/* Modal for showing subscription change details */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Subscription Change Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : modalError ? (
            <Alert variant="danger">{modalError}</Alert>
          ) : (
            <div>
              {modalData ? (
                <>
                  <p>
                    <strong>Domain Name:</strong> {modalData.domain_name}
                  </p>
                  <p>
                    <strong>Customer ID:</strong> {modalData.customer_id}
                  </p>
                  <p>
                    <strong>Customer Name:</strong>{" "}
                    {modalData.addressdetails?.contactName}
                  </p>
                  <p>
                    <strong>Organization Name:</strong>{" "}
                    {modalData.addressdetails?.organizationName}
                  </p>
                  <div
                    className="table-responsive"
                    style={{ maxHeight: "400px", overflowY: "auto" }}
                  >
                    <table className="table table-hover sticky-header">
                      <thead>
                        <tr>
                          <th>SKU Name</th>
                          <th>Start Date(mm-dd-yyy)</th>
                          <th>End Date(mm-dd-yyy)</th>
                          <th>Creation Date</th>
                          <th>Plan Name</th>
                          <th>Is Commitment</th>
                          <th>Number of Seats</th>
                          <th>Number of Licenses</th>
                          <th>Changed Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modalData.data?.flatMap((subscriptionArray, index) =>
                          Array.isArray(subscriptionArray)
                            ? subscriptionArray.map(
                                (subscription, innerIndex) => (
                                  <tr key={`${index}-${innerIndex}`}>
                                    <td>{subscription.sku_name}</td>
                                    <td>{subscription.startdate}</td>
                                    <td>{subscription.enddate}</td>
                                    <td>{subscription.creationTime}</td>
                                    <td>{subscription.plan_name}</td>
                                    <td>{subscription.is_commitment}</td>
                                    <td>{subscription.number_of_seats}</td>
                                    <td>{subscription.licensed_seats}</td>
                                    <td>{subscription.created_on}</td>
                                    <td>{subscription.status}</td>
                                  </tr>
                                )
                              )
                            : null
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <p>No data available.</p>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default SubscriptionDetailsPaymentSourceIN;
