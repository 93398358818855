import React, { Fragment, useState, useEffect } from "react";
import { usersignup } from "../../utils/api"; // Import the signup and sendOTP API functions

const UserSignupForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true); // State to track email validity
  const [password, setPassword] = useState("");
  const [cnfpassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Signup Page"; // Set the document title
  }, []);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailValid(validateEmail(emailValue));

    const emailInput = e.target;
    if (validateEmail(emailValue)) {
      emailInput.style.borderColor = "green";
      setEmailError("");
    } else {
      emailInput.style.borderColor = "red";
      setEmailError("Please enter a valid email address.");
    }
  };

  const validateEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!password || !cnfpassword) {
      window.alert("Please enter password and confirm password.");
      return;
    }

    if (password !== cnfpassword) {
      window.alert("Passwords do not match.");
      return;
    }

    setLoading(true);
    try {
      // const formData = new FormData();
      // formData.append("name", name);
      // formData.append("company", company);
      // formData.append("email", email);
      // formData.append("password", password);
      // formData.append("cnfpassword", cnfpassword);
      // formData.append("phnno", phnno);
      // if (logo) {
      //   formData.append("logo", logo); // Append logo to form data if available
      // }

      const response = await usersignup(name, email, password, cnfpassword);
      if (response.status === 200) {
        window.alert(
          "User registered successfully."
        );
        window.location.href = "/userlogin";
      } else {
        console.log(response);
        const data = await response.json(); // Extract error message from response body
        window.alert(data.message); // Display error message in alert
      }
      setName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setLoading(false);
    } catch (error) {
      window.alert("Signup failed. Please try again.");
      setLoading(false);
    }
  };

  const handleLoginLinkClick = () => {
    window.location.href = "/userlogin"; // Redirect to the login page
  };

  return (
    <Fragment>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth register-bg-1 theme-one">
            <div className="row w-100">
              <div className="col-lg-4 mx-auto text-center">
                <img
                  src="assets/images/shivaami200.png"
                  className="img"
                  alt=""
                />
                <h1 className="mb-5">User Account Register</h1>
                <div className="auto-form-wrapper">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="email"
                          className={`form-control ${
                            emailValid ? "" : "is-invalid"
                          }`}
                          placeholder="Email ID"
                          value={email}
                          onChange={handleEmailChange}
                          title="Enter a valid email address"
                          required
                        />
                        <br></br>
                        {/* {!emailValid && (
                          <div className="invalid-feedback">
                            Please enter a valid email address.
                          </div>
                        )} */}
                        <br></br>
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm Password"
                            value={cnfpassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        className="btn btn-primary submit-btn btn-block"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                    <div className="text-block text-center my-3">
                      <span className="text-small font-weight-semibold">
                        Already have an account?
                      </span>
                      <a
                        href="/userLogin"
                        className="text-black text-small"
                        onClick={handleLoginLinkClick}
                      >
                        Login
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script src="../../assets/vendors/js/vendor.bundle.base.js"></script>
      <script src="../../assets/vendors/js/vendor.bundle.addons.js"></script>
      <script src="../../assets/js/shared/off-canvas.js"></script>
      <script src="../../assets/js/shared/misc.js"></script>
      <script
        src="../../assets/js/shared/jquery.cookie.js"
        type="text/javascript"
      ></script>
    </Fragment>
  );
};
export default UserSignupForm;
