import React, { Fragment, useState, useEffect } from "react";
import { getSubHistData } from "../../utils/api"; // Assuming you have an API function for getting customer cases list
import { useCookies } from "react-cookie";
import { Modal, Button, Dropdown, Form } from "react-bootstrap";
import { BsThreeDotsVertical, BsFilter } from "react-icons/bs";
// import { FaEdit } from "react-icons/fa";

const SubscriptionHistDetails = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [initialUserList, setInitialUserList] = useState([]);
  //   const [email, setEmail] = useState("");
  //   const [showModal, setShowModal] = useState(false);
  //   const [selectedCase, setSelectedCase] = useState(null);
  const [showNewCaseModal, setShowNewCaseModal] = useState(false);
  //   const [newCase, setNewCase] = useState({
  //     customer_name: "",
  //     customer_emailid: "",
  //     reseller_id: "",
  //     status: "",
  //     additional_information: "",
  //     cust_id: "",
  //     created_date: "",
  //     domain_name: "",
  //     prod_details: [],
  //     plantype: "",
  //     planduration: "",
  //   });
  //   const [customers, setCustomers] = useState([]);
  //   const [productData, setProductData] = useState({});
  //   const [selectedOEM, setSelectedOEM] = useState("");
  //   const [selectedProduct, setSelectedProduct] = useState("");
  //   const [selectedSubproduct, setSelectedSubproduct] = useState("");
  //   const [subproductList, setSubproductList] = useState([]);
  //   const [userCount, setUserCount] = useState("");
  //   const [products, setProducts] = useState([]);
  //   const [showEditModal, setShowEditModal] = useState(false);
  //   const [selectedProductIndex, setSelectedProductIndex] = useState(null);
  //   const [editedProduct, setEditedProduct] = useState(null);
  //   const [additionalInfo, setAdditionalInfo] = useState(
  //     selectedCase?.additional_information || ""
  //   );
  //   const [planType, setPlanType] = useState(selectedCase?.plan_type || "");
  //   const [planDuration, setPlanDuration] = useState(
  //     selectedCase?.plan_duration || ""
  //   );
  //   const [newProduct, setNewProduct] = useState({
  //     oemName: "",
  //     productName: "",
  //     subproductName: "",
  //     userCount: "",
  //     plan_type: "",
  //     plan_duration: "",
  //   });
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSubHistData();
        console.log(data);
        setSubscriptions(data);
        setInitialUserList(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
    const filteredList = initialUserList.filter((subcription) => {
      if (subcription && subcription.customer_domain) {
        return subcription.customer_domain
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return false;
      }
    });
    setSubscriptions(filteredList);
  };

  const handleReset = () => {
    setSearchInput("");
    setSubscriptions(initialUserList);
  };
  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };

  return (
    <Fragment>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Subscriptions History Lists</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search by domain name..."
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
            </div>
            <br></br>
            <br></br>
            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th style={thStyles}>Domain Name</th>
                    <th style={thStyles}>Customer ID</th>
                    <th style={thStyles}>Subscription ID</th>
                    <th style={thStyles}>SKU Name</th>
                    <th style={thStyles}>Creation Date</th>
                    <th style={thStyles}>Plan Name</th>
                    <th style={thStyles}>IS Commitment</th>
                    <th style={thStyles}>Max Seats</th>
                    <th style={thStyles}>Number of seats</th>
                    <th style={thStyles}>Licensed Seats</th>
                    <th style={thStyles}>Start Date(mm-dd-yyy)</th>
                    <th style={thStyles}>End Date(mm-dd-yyy)</th>
                    <th style={thStyles}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptions.map((subcription) => (
                    <tr key={subcription.g_id}>
                      <td>{subcription.customer_domain}</td>
                      <td>{subcription.customer_id}</td>
                      <td>{subcription.subscription_id}</td>
                      <td>{subcription.sku_name}</td>
                      <td>{subcription.creationTime}</td>
                      <td>{subcription.plan_name}</td>
                      <td>
                        {subcription.is_commitment === "1" ? "True" : "False"}
                      </td>
                      <td>{subcription.max_seats}</td>
                      <td>{subcription.number_of_seats}</td>
                      <td>{subcription.licensed_seats}</td>
                      <td>{subcription.startdate}</td>
                      <td>{subcription.enddate}</td>
                      <td>{subcription.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SubscriptionHistDetails;
