import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AddProduct from "./addproduct";
import SubscriptionDetails from "./subscriptiondetails";
import SubscriptionChngDetails from "./getdomainreport";
import SubscriptionHistDetails from "./subscriptiondetailschangehistory";
import CreateSubscription from "./createSubscription";
import SubscriptionDetailsPaymentSource from "./domainpaymentsourcedetails";
import SubscriptionDetailsPaymentSourceIN from "./domainpaymentsourcedetailsindia";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "./components/footer";
import Navbar from "./components/navbar";

const UserMainComponent = () => {
  const isLoggedIn = true; // Example: Set to true if the user is logged in

  const [selectedOption, setSelectedOption] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Check initial window width
    window.addEventListener("resize", handleResize); // Add event listener for resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener
    };
  }, []);

  return (
    <Fragment>
      <div
        className={`container-scroller ${isSidebarOpen ? "sidebar-open" : ""}`}
      >
        <Navbar isAdmin={false} />
        <div className="container-fluid page-body-wrapper">
          <nav
            className={`sidebar ${isSidebarOpen ? "sidebar-offcanvas" : ""}`}
            id="sidebar"
          >
            <ul className="nav">
              {/* <li className="nav-item nav-profile">
                <a href="#" className="nav-link">
                  <div className="profile-image">
                    <img
                      className="img-xs rounded-circle"
                      src="assets/images/faces/face8.jpg"
                      alt="profile image"
                    />
                    <div className="dot-indicator bg-success"></div>
                  </div>
                  <div className="text-wrapper">
                    <p className="profile-name">
                      {parsedLoginData.reseller_name}
                    </p>
                    <p className="designation">Reseller</p>
                  </div>
                </a>
              </li> */}
              <li className="nav-item nav-category">Main Menu</li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Subscription Details USA")}
                >
                  Subscription Details USA
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Subscription Details India")}
                >
                  Subscription Details India
                </Link>
              </li>
            </ul>
          </nav>
          <div className="main-panel">
            {/* {errormessage && (
              <div className="alert alert-danger  my-2 py-1" role="alert">
                {errormessage}
              </div>
            )} */}
            {isMobileView && (
              <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
                {isSidebarOpen ? (
                  <span>&#x2630;&#x2630;&#x2630;</span>
                ) : (
                  <span>&#x2630;&#x2630;&#x2630;</span>
                )}
              </button>
            )}
            <div className="content-wrapper">
              <div className="row page-title-header">
                <div className="col-12">
                  <div className="page-header">
                    <div className="quick-link-wrapper w-100 d-md-flex flex-md-wrap">
                    </div>
                  </div>
                </div>
              </div>
              {selectedOption === "Subscription Details USA" && (
                <div className="customer-dashboard-form-wrapper">
                  <SubscriptionDetailsPaymentSource />
                </div>
              )}
              {selectedOption === "Subscription Details India" && (
                <div className="customer-dashboard-form-wrapper">
                  <SubscriptionDetailsPaymentSourceIN />
                </div>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <script src="assets/vendors/js/vendor.bundle.base.js"></script>
      <script src="assets/vendors/js/vendor.bundle.addons.js"></script>
      <script src="assets/js/shared/off-canvas.js"></script>
      <script src="assets/js/shared/misc.js"></script>
      <script src="assets/js/demo_1/dashboard.js"></script>
      <script
        src="assets/js/shared/jquery.cookie.js"
        type="text/javascript"
      ></script>
    </Fragment>
  );
};

export default UserMainComponent;
