import React, { Fragment, useState } from "react";
import { Form, Button, Spinner, Alert, Table } from "react-bootstrap";
import { fetchSubscriptionByDomain, updateSubscrip } from "../../utils/api"; // Assuming this API function exists

const UpdateSubscription = () => {
  const [domain, setDomain] = useState("");
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editProductId, setEditProductId] = useState(null);
  const [quantity, setQuantity] = useState(0);

  const handleDomainSearch = async () => {
    try {
      setLoading(true);
      const response = await fetchSubscriptionByDomain(domain);
      setCustomerData(response.data[0]);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch subscription data:", error.message);
      setError("Failed to fetch subscription data. Please try again.");
      setLoading(false);
    }
  };

  const handleEditQuantity = (product) => {
    setEditProductId(product.subscription_id_stripe);
    setQuantity(product.quantity);
  };

  const handleQuantityChange = (delta) => {
    setQuantity((prevQuantity) => Math.max(parseInt(prevQuantity) + delta, 0));
  };

  const handleSaveQuantity = (product) => {
    const updatedCustomerData = { ...customerData };
    updatedCustomerData.subscriptions = updatedCustomerData.subscriptions.map(
      (subscription) => {
        subscription.products = subscription.products.map((prod) => {
          if (prod.subscription_id_stripe === product.subscription_id_stripe) {
            prod.quantity = quantity;
          }
          return prod;
        });
        return subscription;
      }
    );
    setCustomerData(updatedCustomerData);
    setEditProductId(null);
  };

  const handleUpdateSubscription = async () => {
    try {
      setLoading(true);

      const subscriptions = customerData.subscriptions.map((subscription) => ({
        subscription_id_stripe: subscription.subscription_id_stripe,
        duration_start_date: subscription.duration_start_date,
        duration_end_date: subscription.duration_end_date,
        payment_frequency: subscription.payment_frequency,
        commitment: subscription.commitment,
        plan_type: subscription.plan_type,
        products: subscription.products.map((product) => ({
          subscription_id_stripe: product.subscription_id_stripe,
          product_name: product.product_name,
          product_id: product.product_id,
          price_id: product.price_id,
          invoice_id: product.invoice_id,
          buyingprice: product.buyingprice,
          sellingprice: product.sellingprice,
          date: product.date,
          quantity: product.quantity,
        })),
      }));

      const data = {
        customer_id: customerData.cust_id_stripe,
        customer_name: customerData.customer_name,
        customer_email: customerData.customer_email,
        customer_domain: customerData.domain_name,
        description: customerData.description,
        subscriptions: subscriptions,
      };
      console.log(data);
      // Assuming you have an updateSubscription function to handle API update
      const responseData = await updateSubscrip(data);
      if (responseData.success) {
        window.alert("Subscription updated successfully!");
        setCustomerData(null); // Reset customerData state after successful update
      } else {
        window.alert("Failed to update subscription.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Failed to update subscription:", error.message);
      setError("Failed to update subscription. Please try again.");
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Update Subscription Form</h4>
            <br />
            <Form className="form-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Domain</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter domain"
                        value={domain}
                        onChange={(e) => setDomain(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <Button
                    variant="primary"
                    onClick={handleDomainSearch}
                    disabled={loading}
                    className="ml-2"
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Search"
                    )}
                  </Button>
                </div>
              </div>
              {customerData && (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label">
                          <strong>Customer Name</strong>
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            value={customerData.customer_name}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label">
                          <strong>Customer Email</strong>
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="email"
                            className="form-control"
                            value={customerData.customer_email}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label">
                          <strong>Customer Company</strong>
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            value={customerData.customer_company_name}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label">
                          <strong>Domain</strong>
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            value={customerData.domain_name}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Payment Frequency</th>
                    <th>Commitment</th>
                    <th>Plan Type</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {customerData &&
                    customerData.subscriptions.map((subscription) =>
                      subscription.products.map((product) => (
                        <tr key={product.subscription_id_stripe}>
                          <td>{product.product_name}</td>
                          <td>{subscription.payment_frequency}</td>
                          <td>{subscription.commitment}</td>
                          <td>{subscription.plan_type}</td>
                          <td>{product.sellingprice}</td>
                          <td>
                            {editProductId ===
                            product.subscription_id_stripe ? (
                              <div className="d-flex align-items-center">
                                {subscription.plan_type === "Flexible" && (
                                  <Button
                                    variant="secondary"
                                    onClick={() => handleQuantityChange(-1)}
                                    disabled={quantity <= 0}
                                  >
                                    -
                                  </Button>
                                )}
                                <Form.Control
                                  type="text"
                                  value={quantity}
                                  readOnly
                                  className="mx-2"
                                  style={{ width: "60px", textAlign: "center" }}
                                />
                                <Button
                                  variant="secondary"
                                  onClick={() => handleQuantityChange(1)}
                                >
                                  +
                                </Button>
                              </div>
                            ) : (
                              product.quantity
                            )}
                          </td>
                          <td>
                            {editProductId ===
                            product.subscription_id_stripe ? (
                              <Button
                                variant="success"
                                size="sm"
                                onClick={() => handleSaveQuantity(product)}
                              >
                                Save
                              </Button>
                            ) : (
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() => handleEditQuantity(product)}
                              >
                                Edit
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                </tbody>
              </Table>
              <br />
              {error && <Alert variant="danger">{error}</Alert>}
              {customerData && (
                <div className="row">
                  <div className="col-md-12">
                    <Button
                      variant="primary"
                      onClick={handleUpdateSubscription}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Update Subscription"
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateSubscription;
