import React, { Fragment, useState, useEffect } from "react";
import { getDomainChngdData } from "../../utils/api";
import { Modal, Button, Form, Spinner, Alert } from "react-bootstrap";

const SubscriptionChngDetails = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [initialUserList, setInitialUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [domainName, setDomainName] = useState(""); // New state for domain name
  const [customerId, setCustomerId] = useState(""); // New state for customer id
  const [addressDetails, setAddressDetails] = useState({});
  const [customerName, setcustomerName] = useState(""); // New state for domain name
  const [organizationName, setorganizationName] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (domainName = "") => {
    setLoading(true);
    try {
      const data = await getDomainChngdData(domainName);
      if (Array.isArray(data.data)) {
        // Check if data is an array
        setSubscriptions(data.data);
        setInitialUserList(data.data);
        setDomainName(data.domain_name);
        setCustomerId(data.customer_id);
        setcustomerName(data.addressdetails.contactName);
        setorganizationName(data.addressdetails.organizationName);
        setError(null); // Clear any previous errors
      } else {
        setError("Invalid data format received."); // Handle unexpected data format
      }
    } catch (error) {
      console.error("Failed to fetch data:", error.message);
      setError("Failed to fetch data. Please try again."); // Set error message
    } finally {
      setLoading(false);
    }
  };

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
  };

  const handleSearch = () => {
    fetchData(searchInput);
  };

  const handleReset = () => {
    setSearchInput("");
    setSubscriptions(initialUserList);
    setAddressDetails("");
    setCustomerId("");
    setDomainName("");
    setcustomerName("");
    setorganizationName("");
    setError(null); // Clear any previous errors
    window.location.reload();
  };

  const renderTableRows = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan="9" style={{ textAlign: "center" }}>
            <Spinner animation="border" variant="primary" />
          </td>
        </tr>
      );
    }

    if (error) {
      return (
        <tr>
          <td colSpan="9">
            <Alert variant="danger">{error}</Alert>
          </td>
        </tr>
      );
    }

    // Render table rows
    return subscriptions.flatMap((subscriptionArray, index) =>
      Array.isArray(subscriptionArray) ? (
        subscriptionArray.map((subscription, innerIndex) => (
          <tr key={`${index}-${innerIndex}`}>
            <td>{subscription.sku_name}</td>
            <td>{subscription.startdate}</td>
            <td>{subscription.enddate}</td>
            <td>{subscription.creationTime}</td>
            <td>{subscription.plan_name}</td>
            <td>{subscription.is_commitment}</td>
            <td>{subscription.number_of_seats}</td>
            <td>{subscription.licensed_seats}</td>
            <td>{subscription.created_on}</td>
            <td>{subscription.status}</td>
          </tr>
        ))
      ) : (
        <tr key={index}>
          <td colSpan="7">Invalid data format in subscriptionArray</td>
        </tr>
      )
    );
  };

  return (
    <Fragment>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Subscriptions Change History Lists</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search by domain name..."
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <Button
                variant="primary"
                className="ml-2"
                onClick={handleSearch}
                style={{ float: "left" }}
              >
                Search
              </Button>
              <Button
                variant="secondary"
                className="ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </Button>
            </div>
            <br />
            <div style={{ float: "left" }}>
              <p>
                <strong>Domain Name:</strong> {domainName}
              </p>
              <p>
                <strong>Customer ID:</strong> {customerId}
              </p>
              <p>
                <strong>Customer Name:</strong> {customerName}
              </p>
              <p>
                <strong>Organization Name:</strong> {organizationName}
              </p>
            </div>
            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th>SKU Name</th>
                    <th>Start Date(mm-dd-yyy)</th>
                    <th>End Date(mm-dd-yyy)</th>
                    <th>Creation Date</th>
                    <th>Plan Name</th>
                    <th>Is Commitment</th>
                    <th>Number of Seats</th>
                    <th>Number of Licenes</th>
                    <th>Changed Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SubscriptionChngDetails;
