import { API_HOST, API_HOST_INDIA } from "./config";

// Or, if you used default export
import config from "./config";
// const { API_HOST, API_HOST_INDIA } = config;

///////////////////////////////////////USA API CODES////////////////////////////////////////
export const getLatestData = async () => {
  try {
    const response = await fetch(`${API_HOST}/getusaresellersdetails/`, {
      method: "POST",
    });
    // console.log(response.json());
    // const data = await response.json();
    // console.log("asdsa", data);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const getMasterData = async () => {
  try {
    const response = await fetch(`${API_HOST}/get_master_data/`, {
      method: "GET",
    });
    // console.log(response.json());
    const data = await response.json();
    // console.log("asdsa", data);
    return data.data;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const getSubHistData = async () => {
  try {
    const response = await fetch(`${API_HOST}/get_subscription_history_data/`, {
      method: "GET",
    });
    // console.log(response.json());
    const data = await response.json();
    // console.log("asdsa", data);
    return data.data;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const getDomainChngdData = async (domain_name) => {
  const formdata = new FormData();
  formdata.append("domain_name", domain_name);
  try {
    const response = await fetch(
      `${API_HOST}/get_subscription_change_history/`,
      {
        method: "POST",
        body: formdata,
      }
    );
    // console.log(response.json());
    const data = await response.json();
    // console.log("asdsa", data);
    return data;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const addProducts = async (
  productName,
  buyingPrice,
  sellingPrice,
  paymentTerms,
  commitmentPeriod,
  priceTerm
) => {
  const formdata = new FormData();
  formdata.append("skuname", productName);
  formdata.append("buying_price", buyingPrice);
  formdata.append("selling_price", sellingPrice);
  formdata.append("payment_terms", paymentTerms);
  formdata.append("commitment_period", commitmentPeriod);
  formdata.append("price_pr", priceTerm);
  try {
    const response = await fetch(`${API_HOST}/add_productin_stripe/`, {
      method: "POST",
      body: formdata,
    });
    // console.log(response.json());
    // const data = await response.json();
    // console.log("asdsa", data);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const fetchProducts = async () => {
  try {
    const response = await fetch(`${API_HOST}/get_products_details/`, {
      method: "GET",
    });
    // console.log(response.json());
    const data = await response.json();
    console.log("asdsa", data);
    return data.products_data;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const createSubscrip = async (data) => {
  try {
    const response = await fetch(`${API_HOST}/create_subcriptionin_stripe/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    // // console.log(response.json());
    // const data = await response.json();
    // console.log("asdsa", data);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const fetchSubscriptionByDomain = async (domain) => {
  try {
    const formdata = new FormData();
    formdata.append("domain_name", domain);
    const response = await fetch(`${API_HOST}/get_subscription_details/`, {
      method: "POST",
      body: formdata,
    });
    // console.log(response.json());
    const data = await response.json();
    console.log("asdsa", data);
    return data;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const updateSubscrip = async (data) => {
  try {
    const response = await fetch(`${API_HOST}/update_subcriptionin_stripe/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    // // console.log(response.json());
    // const data = await response.json();
    // console.log("asdsa", data);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};
export const getDomainDetails = async () => {
  try {
    const response = await fetch(`${API_HOST}/get_domaindetails/`, {
      method: "GET",
    });
    // // console.log(response.json());
    const data = await response.json();
    // console.log("asdsa", data);
    return data.data;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

///////////////////////////////////////INDIA API CODES////////////////////////////////////////
export const getLatestDataIN = async () => {
  try {
    const response = await fetch(
      `${API_HOST_INDIA}/getindiaresellersdetails/`,
      {
        method: "POST",
      }
    );
    // console.log(response.json());
    // const data = await response.json();
    // console.log("asdsa", data);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const getMasterDataIN = async () => {
  try {
    const response = await fetch(`${API_HOST_INDIA}/get_master_data/`, {
      method: "GET",
    });
    // console.log(response.json());
    const data = await response.json();
    // console.log("asdsa", data);
    return data.data;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const getSubHistDataIN = async () => {
  try {
    const response = await fetch(
      `${API_HOST_INDIA}/get_subscription_history_data/`,
      {
        method: "GET",
      }
    );
    // console.log(response.json());
    const data = await response.json();
    // console.log("asdsa", data);
    return data.data;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const getDomainChngdDataIN = async (domain_name) => {
  const formdata = new FormData();
  formdata.append("domain_name", domain_name);
  try {
    const response = await fetch(
      `${API_HOST_INDIA}/get_subscription_change_history/`,
      {
        method: "POST",
        body: formdata,
      }
    );
    // console.log(response.json());
    const data = await response.json();
    // console.log("asdsa", data);
    return data;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const addProductsIN = async (
  productName,
  buyingPrice,
  sellingPrice,
  paymentTerms,
  commitmentPeriod,
  priceTerm
) => {
  const formdata = new FormData();
  formdata.append("skuname", productName);
  formdata.append("buying_price", buyingPrice);
  formdata.append("selling_price", sellingPrice);
  formdata.append("payment_terms", paymentTerms);
  formdata.append("commitment_period", commitmentPeriod);
  formdata.append("price_pr", priceTerm);
  try {
    const response = await fetch(`${API_HOST_INDIA}/add_productin_stripe/`, {
      method: "POST",
      body: formdata,
    });
    // console.log(response.json());
    // const data = await response.json();
    // console.log("asdsa", data);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const fetchProductsIN = async () => {
  try {
    const response = await fetch(`${API_HOST_INDIA}/get_products_details/`, {
      method: "GET",
    });
    // console.log(response.json());
    const data = await response.json();
    console.log("asdsa", data);
    return data.products_data;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const createSubscripIN = async (data) => {
  try {
    const response = await fetch(
      `${API_HOST_INDIA}/create_subcriptionin_stripe/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );
    // // console.log(response.json());
    // const data = await response.json();
    // console.log("asdsa", data);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const fetchSubscriptionByDomainIN = async (domain) => {
  try {
    const formdata = new FormData();
    formdata.append("domain_name", domain);
    const response = await fetch(
      `${API_HOST_INDIA}/get_subscription_details/`,
      {
        method: "POST",
        body: formdata,
      }
    );
    // console.log(response.json());
    const data = await response.json();
    console.log("asdsa", data);
    return data;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const updateSubscripIN = async (data) => {
  try {
    const response = await fetch(
      `${API_HOST_INDIA}/update_subcriptionin_stripe/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );
    // // console.log(response.json());
    // const data = await response.json();
    // console.log("asdsa", data);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};
export const getDomainDetailsIN = async () => {
  try {
    const response = await fetch(`${API_HOST_INDIA}/get_domaindetails/`, {
      method: "GET",
    });
    // // console.log(response.json());
    const data = await response.json();
    // console.log("asdsa", data);
    return data.data;
  } catch (error) {
    throw new Error("Failed to fetch data: " + error.message);
  }
};

export const usersignup = async (
  name,
  email,
  password,
  confirmPassword,
) => {
  try {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("cnfpassword", confirmPassword);
    console.log(name, email, password, confirmPassword);
    const response = await fetch(`${API_HOST}/register_user/`, {
      method: "POST",
      body: formData,
    });
    console.log(response);
    return response;
  } catch (error) {
    throw new Error("Signup failed: " + error.message);
  }
};
export const userlogin = async (email, password) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    const response = await fetch(`${API_HOST}/user_login/`, {
      method: "POST",
      body: formData,
    });
    //console.log(response.json());
    return response;
  } catch (error) {
    throw new Error("Login failed: " + error.message);
  }
};
export const usersendOTPforget = async (email) => {
  try {
    console.log("email", email);
    const formData = new FormData();
    formData.append("email", email);
    console.log(formData);
    const response = await fetch(`${API_HOST}/userforgotpassword/`, {
      method: "POST",
      body: formData,
    });
    console.log(response);
    return response;
  } catch (error) {
    throw new Error("Failed to send OTP " + error.message);
  }
};
export const userresetpassword = async (
  userIdParam,
  otp,
  newPassword,
  confirmPassword 
) => {
  try {
    const formData = new FormData();
    formData.append("id", userIdParam);
    formData.append("otp", otp);
    formData.append("password", newPassword);
    formData.append("repassword", confirmPassword);
    //console.log(name, company, email, password, confirmPassword);
    const response = await fetch(`${API_HOST}/userreset_password/`, {
      method: "POST",
      body: formData,
    });
    console.log(response);
    return response;
  } catch (error) {
    throw new Error("Reset Password failed: " + error.message);
  }
};