import logo from "./logo.svg";
import "./App.css";
import SubscriptionDetails from "./panel/pages/subscriptiondetails";
import SubscriptionHistDetails from "./panel/pages/subscriptiondetailschangehistory";
import SubscriptionChngDetails from "./panel/pages/getdomainreport";
import AddProduct from "./panel/pages/addproduct";
import CreateSubscription from "./panel/pages/createSubscription";
import UpdateSubscription from "./panel/pages/updatesubscription";
import SubscriptionDetailsPaymentSource from "./panel/pages/domainpaymentsourcedetails";
import SubscriptionDetailsPaymentSourceIN from "./panel/pages/domainpaymentsourcedetailsindia";
import UserMainComponent from "./panel/pages/usermaincomponent";
import UserLogin from "./panel/pages/userlogin";
import UserSignupForm from "./panel/pages/usersignup";
import Userforgotpasswordform from "./panel/pages/userforgetpassword";
import UserResetPassword from "./panel/pages/userrestpassword";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/subdetails" component={SubscriptionDetails} />
          <Route
            path="/subhistorydetails"
            component={SubscriptionHistDetails}
          />
          <Route path="/subchngdetails" component={SubscriptionChngDetails} />
          <Route path="/addproduct" component={AddProduct} />
          <Route path="/createsubscription" component={CreateSubscription} />
          <Route path="/updatesubscription" component={UpdateSubscription} />
          <Route
            path="/subpaysourcedetails"
            component={SubscriptionDetailsPaymentSource}
          />
          <Route path="/usermaincomponent" component={UserMainComponent} />
          <Route path="/userlogin" component={UserLogin} />
          <Route path="/usersignup" component={UserSignupForm} />
          <Route path="/forgetpassword" component={Userforgotpasswordform} />
          <Route path="/userreset" component={UserResetPassword} />
          {/* INDIA ROUTE */}
          <Route
            path="/subpaysourcedetailsin"
            component={SubscriptionDetailsPaymentSourceIN}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
