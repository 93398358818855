import React, { useState, useEffect } from "react";
// import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import {
// //   update_user_account_details,
// //   update_admin_account_details,
// } from "../../utils/api";
// import UserRegistrationFormModal from "./UserRegistrationFormModal"; // Import the UserRegistrationFormModal component

const Navbar = ({ data, isAdmin }) => {
//   const [showProfileModal, setShowProfileModal] = useState(false);
//   const [showRegistrationModal, setShowRegistrationModal] = useState(false);
//   const [profileInfo, setProfileInfo] = useState({
//     name: "",
//     companyName: "",
//     email: "",
//     phoneNumber: "",
//   });

  // Set default values based on isAdmin
//   useEffect(() => {
//     if (isAdmin) {
//       setProfileInfo({
//         name: data.username || "",
//         email: data.email || "",
//         phoneNumber: data.phone || "",
//       });
//     } else {
//       setProfileInfo({
//         name: data.reseller_name || "",
//         companyName: data.company_name || "",
//         email: data.reseller_email || "",
//         phoneNumber: data.phone_number || "",
//       });
//     }
//   }, [isAdmin, data]);

  const handleSignOut = () => {
    let logoutUrl = "/";
    logoutUrl = "/userlogin"; // Redirect to user login page
    window.location.href = logoutUrl;
  };

//   const handleProfileModal = () => {
//     setShowProfileModal(!showProfileModal);
//   };

//   const openUserRegistrationPopup = () => {
//     setShowRegistrationModal(true); // Show the registration modal
//   };

//   const handleUpdateProfile = () => {
//     const updateFunction = isAdmin
//       ? update_admin_account_details
//       : update_user_account_details;

//     // Validate profileInfo fields
//     const isValidProfile = Object.values(profileInfo).every(
//       (value) => value !== undefined && value !== ""
//     );

//     if (isValidProfile) {
//       updateFunction(profileInfo)
//         .then((response) => {
//           console.log("Profile Updated:", response);
//           setShowProfileModal(false);
//         })
//         .catch((error) => {
//           console.error("Error updating profile:", error);
//         });
//     } else {
//       console.error("Invalid profile data:", profileInfo);
//     }
//   };

  return (
    <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      {/* <UserRegistrationFormModal
        show={showRegistrationModal}
        onHide={() => setShowRegistrationModal(false)}
      /> */}
      <div className="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
        <a className="navbar-brand brand-logo" href="">
          <img src="assets/images/shivaami200.png" alt="logo" />
        </a>
        <a className="navbar-brand brand-logo-mini" href="">
          <img src="assets/images/logo-mini.svg" alt="logo" />
        </a>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center">
        {/* <ul className="navbar-nav">
          <li className="nav-item font-weight-semibold d-none d-lg-block">
            {data.reseller_logo && (
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <img
                    src={data.reseller_logo}
                    alt="Reseller Logo"
                    style={{
                      height: "60px",
                      width: "100px",
                      marginRight: "10px",
                    }}
                  />
                </li>
              </ul>
            )}
          </li>
        </ul> */}

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown d-none d-xl-inline-block user-dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="UserDropdown"
              href="#"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              {/* <img
                className="img-xs rounded-circle"
                src="assets/images/faces/face8.jpg"
                alt="Profile image"
              />
              {data.username && <span className="ml-2">{data.username}</span>}
              {data.reseller_name && (
                <span className="ml-2">{data.reseller_name}</span>
              )} */}
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="UserDropdown"
            >
              <div className="dropdown-header text-center">
                <img
                  className="img-md rounded-circle"
                  src="assets/images/faces/face8.jpg"
                  alt="Profile image"
                />
                {/* {data.username && (
                  <p className="mb-1 mt-3 font-weight-semibold">
                    {data.username}
                  </p>
                )}
                {data.email && (
                  <p className="font-weight-light text-muted mb-0">
                    {data.email}
                  </p>
                )}
                {data.reseller_name && (
                  <p className="mb-1 mt-3 font-weight-semibold">
                    {data.reseller_name}
                  </p>
                )}
                {data.reseller_email && (
                  <p className="font-weight-light text-muted mb-0">
                    {data.reseller_email}
                  </p>
                )} */}
              </div>
              {/* <a
                className="dropdown-item"
                // onClick={handleProfileModal}
                style={{ cursor: "pointer" }}
              >
                My Profile
                <i className="dropdown-item-icon ti-dashboard"></i>
              </a> */}
              {/* {!isAdmin && (
                <a
                  className="dropdown-item"
                //   onClick={openUserRegistrationPopup}
                  style={{ cursor: "pointer" }}
                >
                  Registration Details
                  <i className="dropdown-item-icon ti-dashboard"></i>
                </a>
              )} */}
              <a
                className="dropdown-item"
                onClick={handleSignOut}
                style={{ cursor: "pointer" }}
              >
                Sign Out<i className="dropdown-item-icon ti-power-off"></i>
              </a>
            </div>
          </li>
        </ul>
      </div>

      {/* <Modal show={showProfileModal} onHide={handleProfileModal}>
        <Modal.Header>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {isAdmin ? (
              <>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={profileInfo.name}
                    onChange={(e) =>
                      setProfileInfo({ ...profileInfo, name: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={profileInfo.email}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    value={profileInfo.phoneNumber}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        phoneNumber: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={profileInfo.name}
                    onChange={(e) =>
                      setProfileInfo({ ...profileInfo, name: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={profileInfo.companyName}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        companyName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={profileInfo.email}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    value={profileInfo.phoneNumber}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        phoneNumber: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleProfileModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateProfile}>
            Update
          </Button>
        </Modal.Footer>
      </Modal> */}
    </nav>
  );
};

export default Navbar;
