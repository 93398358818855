export const API_HOST = "https://payment-api.shivaami.com/resellerusa";
export const API_HOST_INDIA = "https://payment-api.shivaami.com/resellerindia";

// Alternatively, you can use default export for a single object containing both variables
const config = {
  API_HOST,
  API_HOST_INDIA
};

export default config;
