import React, { Fragment, useState } from "react";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { addProducts } from "../../utils/api";

const AddProduct = () => {
  // States for new product form
  const [productName, setProductName] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [buyingPrice, setBuyingPrice] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [commitmentPeriod, setCommitmentPeriod] = useState("");
  const [priceTerm, setPriceTerm] = useState("monthly"); // Default to monthly
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleAddProduct = async () => {
    try {
      setLoading(true);
      const data = await addProducts(
        productName,
        buyingPrice,
        sellingPrice,
        paymentTerms,
        commitmentPeriod,
        priceTerm
      );
      console.log("Response from API:", data);
      if (data.status === 200) {
        const response = await data.json();
        console.log(response)
        window.alert(response.message);
        setLoading(false);
        // Reset form fields after successful submission
        setProductName("");
        setSellingPrice("");
        setBuyingPrice("");
        setPaymentTerms("");
        setCommitmentPeriod("");
        setPriceTerm("monthly"); // Reset price term selection
      } else {
        const response = await data.json();
        window.alert(response.message);
        setError(null); // Clear any previous errors
      }
    } catch (error) {
      console.error("Failed to add product:", error.message);
      setLoading(false);
      // setError("Failed to add product. Please try again.");
    }
  };

  return (
    <Fragment>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Add Product</h4>
            <br />
            <form className="form-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Product Name</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter product name"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Buying Price</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter buying price"
                        value={buyingPrice}
                        onChange={(e) => setBuyingPrice(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Selling Price</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter selling price"
                        value={sellingPrice}
                        onChange={(e) => setSellingPrice(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Payment Terms</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter payment terms"
                        value={paymentTerms}
                        onChange={(e) => setPaymentTerms(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Commitment Period</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter commitment period"
                        value={commitmentPeriod}
                        onChange={(e) => setCommitmentPeriod(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Price Term</strong>
                    </label>
                    <div className="col-sm-8">
                      <select
                        className="form-control"
                        value={priceTerm}
                        onChange={(e) => setPriceTerm(e.target.value)}
                      >
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {error && <Alert variant="danger">{error}</Alert>}
            <Button
              variant="primary"
              onClick={handleAddProduct}
              disabled={loading}
            >
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Add Product"
              )}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddProduct;
