import React, { Fragment, useState, useEffect } from "react";
import { Form, Button, Spinner, Alert, Table } from "react-bootstrap";
import { createSubscrip, fetchProducts } from "../../utils/api"; // Assuming these API functions exist

const CreateSubscription = () => {
  const [customerName, setCustomerName] = useState("");
  const [customerDomain, setCustomerDomain] = useState("");
  const [customerGoogleID, setCustomerGoogleID] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [description, setDescription] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [currency, setCurrency] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [products, setProducts] = useState([]);
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showProductTable, setShowProductTable] = useState(false);
  const [rowCount, setRowCount] = useState(1); // Track number of rows
  useEffect(() => {
    const fetchProductList = async () => {
      try {
        const response = await fetchProducts();
        const formattedProductList = response.map((product) => ({
          id: product[0],
          name: product[1],
          buyingPrice: product[2],
        }));
        setProductList(formattedProductList);
      } catch (error) {
        setError("Failed to fetch products.");
      }
    };
    fetchProductList();
  }, []);

  const handleAddProduct = () => {
    setProducts([
      ...products,
      {
        productId: "",
        productName: "",
        buyingPrice: "",
        sellingPrice: "",
        paymentFrequency: "",
        commitmentYears: calculateCommitmentYears(startDate, endDate),
        plan: "",
        licensedSeats: 0,
      },
    ]);
    setShowProductTable(true);
  };

  const handleProductChange = (index, field, value) => {
    const newProducts = [...products];
    newProducts[index][field] = value;

    if (field === "plan") {
      if (value === "FLEXIBLE") {
        newProducts[index].commitmentYears = 0; // Set default value or handle as needed
      } else {
        newProducts[index].commitmentYears = calculateCommitmentYears(
          startDate,
          endDate
        );
      }
    }

    if (field === "productId") {
      const selectedProduct = productList.find(
        (product) => product.id === value
      );
      if (selectedProduct) {
        newProducts[index].productName = selectedProduct.name;
        newProducts[index].buyingPrice = selectedProduct.buyingPrice;
      }
    }

    if (field === "startDate" || field === "endDate") {
      newProducts[index].commitmentYears = calculateCommitmentYears(
        newProducts[index].startDate,
        newProducts[index].endDate
      );
    }

    setProducts(newProducts);
  };

  const calculateCommitmentYears = (start, end) => {
    if (!start || !end) return 0;
    const startYear = new Date(start).getFullYear();
    const endYear = new Date(end).getFullYear();
    return endYear - startYear;
  };

  const handleDeleteProduct = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);

    // Show or hide product table based on products length
    if (updatedProducts.length === 0) {
      setShowProductTable(false);
    }
  };
  const handleAddRow = () => {
    setProducts([
      ...products,
      {
        productId: "",
        productName: "",
        buyingPrice: "",
        sellingPrice: "",
        paymentFrequency: "",
        commitmentYears: calculateCommitmentYears(startDate, endDate),
        plan: "",
        licensedSeats: 0,
      },
    ]);
    setRowCount(rowCount + 1);
  };

  const handleCreateSubscription = async () => {
    try {
      setLoading(true);

      // Constructing the subscriptions array
      const subscriptions = products.map((product) => {
        const subscription = {
          name: product.productName,
          start_date: startDate, // Assuming startDate is common for all products
          end_date: endDate, // Assuming endDate is common for all products
          payment_frequency: product.paymentFrequency.toLowerCase(), // Adjust as needed
          plan: product.plan,
          licensed_seats: parseInt(product.licensedSeats),
          buyingprice:parseFloat(product.buyingPrice),
          sellingprice: parseFloat(product.sellingPrice), // Assuming sellingPrice is set for each product
        };

        if (product.plan === "ANNUAL") {
          subscription.commitment = product.commitmentYears;
        }

        return subscription;
      });

      // Constructing the metadata object
      const metadata = {
        customer_id: customerGoogleID, // Assuming you want to use customerGoogleID for customer_id
        organization_name: organizationName,
        currency: currency.toLowerCase(), // Adjust as needed
      };

      // Constructing the final data object
      const data = {
        customer_email: customerEmail,
        customer_name: customerName,
        customer_domain:customerDomain,
        description: description,
        metadata: metadata,
        currency: currency.toLowerCase(), // Adjust as needed
        subscriptions: subscriptions,
      };

      // Simulate API call (replace with actual API call)
      console.log("Submitted data:", data);
      // Call API function to create subscription
      const responseData = await createSubscrip(data);
      console.log("API Response:", responseData);
      if (responseData.status === 200) {
        const data = await responseData.json();
        // Reset form fields after successful submission
        setCustomerName("");
        setCustomerGoogleID("");
        setCustomerEmail("");
        setCustomerDomain("")
        setDescription("");
        setOrganizationName("");
        setCurrency("");
        setStartDate("");
        setEndDate("");
        setProducts([]);
        setShowProductTable(false);
        setLoading(false);
        window.alert(data.message);
      } else {
        setLoading(false)
        const data = await responseData.json();
        window.alert(data.message);
      }
      // Handle success or navigation
    } catch (error) {
      console.error("Failed to add customer:", error.message);
      setLoading(false);
      setError("Failed to add customer. Please try again.");
    }
  };

  return (
    <Fragment>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Create Subscription Form</h4>
            <br />
            <form className="form-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Customer Name</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter customer name"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Customer Google ID</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Google ID"
                        value={customerGoogleID}
                        onChange={(e) => setCustomerGoogleID(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Customer Domain</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter customer domain"
                        value={customerDomain}
                        onChange={(e) => setCustomerDomain(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Customer Email</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter customer email"
                        value={customerEmail}
                        onChange={(e) => setCustomerEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Description</strong>
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        placeholder="Enter description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Organization Name</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter organization name"
                        value={organizationName}
                        onChange={(e) => setOrganizationName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Currency</strong>
                    </label>
                    <div className="col-sm-8">
                      <select
                        className="form-control"
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                      >
                        <option value="">Select Currency</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                        {/* Add more currencies as needed */}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Start Date</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>End Date</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {showProductTable && (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      {/* <th>Product ID</th> */}
                      {/* <th>Buying Price(user/month)</th> */}
                      <th>Selling Price(user/month)</th>
                      <th>Plan</th>
                      <th>Payment Frequency</th>
                      <th>Commitment Years</th>
                      <th>Licensed Seats</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product, index) => (
                      <tr key={index}>
                        <td>
                          <select
                            className="form-control"
                            value={product.productId}
                            onChange={(e) =>
                              handleProductChange(
                                index,
                                "productId",
                                e.target.value
                              )
                            }
                          >
                            <option value="">Select product</option>
                            {productList.map((prod) => (
                              <option key={prod.id} value={prod.id}>
                                {prod.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        {/* <td>
                          <input
                            type="text"
                            className="form-control"
                            value={product.productId}
                            readOnly
                          />
                        </td> */}
                        {/* <td>
                          <input
                            type="text"
                            className="form-control"
                            value={product.buyingPrice}
                            readOnly
                          />
                        </td> */}
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            value={product.sellingPrice}
                            onChange={(e) =>
                              handleProductChange(
                                index,
                                "sellingPrice",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <select
                            className="form-control"
                            value={product.plan}
                            onChange={(e) =>
                              handleProductChange(index, "plan", e.target.value)
                            }
                          >
                            <option value="">Select Plan</option>
                            <option value="ANNUAL">Commitment Plan</option>
                            <option value="FLEXIBLE">Flexible Plan</option>
                          </select>
                        </td>
                        <td>
                          <select
                            className="form-control"
                            value={product.paymentFrequency}
                            onChange={(e) =>
                              handleProductChange(
                                index,
                                "paymentFrequency",
                                e.target.value
                              )
                            }
                          >
                            {product.plan === "ANNUAL" ? (
                              <>
                                <option value="">Select frequency</option>
                                <option value="yearly">Yearly</option>
                                <option value="half_yearly">Half Yearly</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="monthly">Monthly</option>
                              </>
                            ) : product.plan === "FLEXIBLE" ? (
                              <>
                                <option value="">Select frequency</option>
                                <option value="monthly">Month</option>
                                <option value="daily">Day</option>
                              </>
                            ) : (
                              <option value="">Select frequency</option>
                            )}
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            value={product.commitmentYears}
                            onChange={(e) =>
                              handleProductChange(
                                index,
                                "commitmentYears",
                                e.target.value
                              )
                            }
                            disabled={product.plan === "FLEXIBLE"}
                          />
                        </td>

                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={product.licensedSeats}
                            onChange={(e) =>
                              handleProductChange(
                                index,
                                "licensedSeats",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Button
                            variant="link"
                            onClick={() => handleDeleteProduct(index)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="9">
                        <Button variant="link" onClick={handleAddRow}>
                          + Add Row
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </form>
            <br></br>
            {error && <Alert variant="danger">{error}</Alert>}
            {!showProductTable && (
              <Button variant="primary" onClick={handleAddProduct}>
                Add Products
              </Button>
            )}
            <Button
              variant="primary"
              onClick={handleCreateSubscription}
              disabled={loading}
              className="ml-2"
            >
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Create Subscription"
              )}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateSubscription;
